// @ts-strict-ignore
import { action, computed, makeObservable, observable } from 'mobx';

import moment from 'moment';

import { defaultLastHomeCareInstructionsOptionClicked } from 'mobx/stores/PathwayStore';

import { secondsPassed } from 'utils/DateUtils';

import { parseBoolean } from 'utils/ParsingUtils';

import Call, {
  CallDurationInterval,
  DurationTypeEnum,
  HomeCareInstructionsDraft
} from 'models/Call';
import { DurationDoctorDetails } from 'models/DurationInterval';
import { PathwayBasicInfo } from 'models/PathwayTemplates';

import { RootStore } from './rootStore';

const LOCAL_STORAGE_CALL_LOGGING_TIMER_ACTIVE_KEY = 'is_call_logging_timer_active';
export default class CallLoggingStore {
  callTimer: any;

  @observable
  wasCallForceStopped = false;

  @observable
  currentCall: Partial<Call> = null;

  @observable
  private isCallLoggingTimerActive = false;

  @observable
  isCallLoggerMinimized = false;

  @observable
  isCallLoggerOpen = false;

  @observable
  preselectedTicketIds: number[] = [];

  @observable
  selectedResumedDraft: Call;

  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    makeObservable(this);
    this.isCallLoggingTimerActive = parseBoolean(
      localStorage.getItem(LOCAL_STORAGE_CALL_LOGGING_TIMER_ACTIVE_KEY),
      false
    );
    window.addEventListener('storage', this.onStorage, false);
    this.rootStore = rootStore;
    this.resetCurrentCall();
  }

  @action
  setSelectedResumedDraft(callDraft: Call) {
    this.selectedResumedDraft = callDraft;
  }

  @action
  updateCurrentCall = (call?: Partial<Call>): void => {
    this.currentCall = {
      ...this.currentCall,
      ...call
    };
  };

  @action
  updateHomeCareInstructionsDraft = (
    homeCareInstructionsDraft: Partial<HomeCareInstructionsDraft>
  ) => {
    this.updateCurrentCall({
      homeCareInstructionsDraft: {
        ...this.currentCall.homeCareInstructionsDraft,
        ...homeCareInstructionsDraft
      }
    });
  };

  hideReturnToCallWarnings = () => {
    if (this.wasCallForceStopped) {
      this.setCallStopped(false);
    }
  };

  endInterval = () => {
    // Add end date to existing interval.
    const { durationIntervals } = this.currentCall;
    durationIntervals[durationIntervals.length - 1] = durationIntervals[
      durationIntervals.length - 1
    ].copyWith({ endDate: new Date() });
    this.updateCurrentCall({
      durationIntervals: [...durationIntervals]
    });
  };

  endCall = (turnOffGlobalTimer = true) => {
    const callLoggingStore = this.rootStore.stores.callLoggingStore;
    callLoggingStore.clearCallTimer();
    this.rootStore.stores.careTimerStore.setIdleDisabled(false);
    this.endInterval();
    callLoggingStore.callTimer = null;
    if (turnOffGlobalTimer) {
      callLoggingStore.setIsGlobalCallLoggingTimerActive(false);
    }
  };

  @action
  resetCurrentCall = (call?: Partial<Call>): void => {
    if (!call) {
      call = {
        id: null,
        note: '',
        callDuration: null,
        documentationDuration: 0,
        durationIntervals: [],
        patientInitiated: false,
        callReasons: [],
        isDraft: false,
        ticketIds: new Set(this.preselectedTicketIds),
        topicsDiscussed: [],
        summary: '',
        smartSummaryId: null,
        includeSummaryInEmrNote: null,
        homeCareInstructions: [],
        homeCareInstructionsDraft: { text: '', phoneNumber: '' }
      };
    }

    this.currentCall = Object.assign({ durationIntervals: [] }, call);
  };

  @computed
  get isGlobalCallLoggingTimerActive(): boolean {
    return this.isCallLoggingTimerActive;
  }

  @computed
  get isOpenAndMinimized(): boolean {
    return this.isCallLoggerOpen && this.isCallLoggerMinimized;
  }

  isTimerActive(): boolean {
    return this.callTimer;
  }

  clearCallTimer = () => {
    clearInterval(this.callTimer);
  };

  @action
  setCallStopped = (wasStopped: boolean) => {
    this.wasCallForceStopped = wasStopped;
  };

  private onStorage = (data: StorageEvent) => {
    if (data.key === LOCAL_STORAGE_CALL_LOGGING_TIMER_ACTIVE_KEY && data.newValue) {
      const isCallLoggingTimerActive = parseBoolean(data.newValue, false);
      if (!isCallLoggingTimerActive && this.isTimerActive()) {
        this.setCallStopped(true);
      }
      this.setIsGlobalCallLoggingTimerActive(isCallLoggingTimerActive);
    }
  };

  @action
  setIsGlobalCallLoggingTimerActive(active: boolean) {
    localStorage.setItem(LOCAL_STORAGE_CALL_LOGGING_TIMER_ACTIVE_KEY, JSON.stringify(active));
    this.isCallLoggingTimerActive = active;
  }

  @action
  setIsCallLoggerOpen(value: boolean) {
    this.isCallLoggerOpen = value;
    if (!value) {
      this.isCallLoggerMinimized = false;
    }
  }

  @action
  toggleMinimize() {
    this.isCallLoggerMinimized = !this.isCallLoggerMinimized;
  }

  @action
  startCallSession = (
    {
      forTicketIds,
      selectedPathway
    }: { forTicketIds?: number[]; selectedPathway?: PathwayBasicInfo } = {
      forTicketIds: [],
      selectedPathway: null
    }
  ) => {
    this.rootStore.stores.pathwaysStore.setCurrentPathwayTemplate(selectedPathway);
    this.preselectedTicketIds = forTicketIds;
    this.setIsCallLoggerOpen(true);
  };

  @action
  startCallTimer = (doctorDetails: DurationDoctorDetails) => {
    const startTime = moment(new Date())
      .subtract(this.currentCall.callDuration, 'seconds')
      .toDate();
    this.createNewInterval(doctorDetails);

    this.updateCurrentCall({ callDuration: secondsPassed(startTime) });

    this.startTimer(() => {
      this.updateCurrentCall({ callDuration: secondsPassed(startTime) });
    }, 1000);
    this.rootStore.stores.careTimerStore.setIdleDisabled(true);
    this.setIsGlobalCallLoggingTimerActive(true);
  };

  @action
  createNewInterval = (doctorDetails: DurationDoctorDetails) => {
    const { durationIntervals } = this.currentCall;
    this.updateCurrentCall({
      durationIntervals: [
        ...durationIntervals,
        new CallDurationInterval(new Date(), null, null, DurationTypeEnum.CALL, doctorDetails)
      ]
    });
  };

  @action reset() {
    this.setIsCallLoggerOpen(false);
    this.preselectedTicketIds = [];
    this.resetCurrentCall();
    this.setSelectedResumedDraft(null);
    this.rootStore.stores.pathwaysStore.setLastHomeCareInstructionsOptionClicked(
      defaultLastHomeCareInstructionsOptionClicked
    );
  }

  startTimer = (onTick: () => void, interval: number) => {
    this.callTimer = setInterval(onTick, interval);
  };

  durationStrFormat = (duration: number) => {
    return moment('2015-01-01').startOf('day').seconds(duration).format('HH:mm:ss');
  };
}
